import React, { useState } from 'react';
import styles from './Deposit.module.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask'; // Импортируем InputMask
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';

export default function Deposit({ theme,account,accounts,setSnackType,setSnackMessage,handleClick }) {
    const { t } = useTranslation();
    const [amount, setAmount] = useState('')
    const [fullname, setFullName] = useState('')
    const {id} = useParams()
    const [depositType, setDepositType] = useState(1);
    const navigate = useNavigate();
    const Replenish = async () => {
        if(!amount || +amount  < 0){
            setSnackType('error')
            handleClick()
            setSnackMessage(t('Amount Error'))
            return
        }
        // if(depositType === 3){
        //     try{
        //         const {data} = await axios.post(process.env.REACT_APP_test_url + `/api/transaction/link`, {
        //             amount: amount,
        //             login: secureLocalStorage.getItem('user').Login
        //         })
        //         if(data){
        //             window.location.href = data.link.url;
        //         }
        //     }catch(e){
        //         console.log(e)
        //     }
        // }else{
            try{
                const {data} = await axios.post(process.env.REACT_APP_test_url + `/api/contact/email/deposit`,
                    {
                        platformid: account?.Id,
                        account: null,
                        type: depositType === 1?'Bank Transfer':depositType === 2?'Crypto currency':'Card',
                        price:+amount
                    },
                    {
                        headers:{
                            'Authorization':'Bearer '+secureLocalStorage.getItem('token')
                        }
                    }
                )
                if(data){
                    setSnackType('success')
                    setSnackMessage(t('ManagerWill'))
                    handleClick()
                }

            }catch(e){
                console.log(e)
            }
        // }
    }

    if(account && account.AccountType === 'Fake') navigate('/cabinet')
    return (
        <div className={styles.withdrawal}>
            <div className={styles.withdrawal_title}>
                <Link to='/cabinet'>
                    <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.67939 5.21532C1.33325 5.84768 1.33325 6.61049 1.33325 8.13611V9.15013C1.33325 11.7507 1.33325 13.051 2.1143 13.8589C2.89535 14.6668 4.15243 14.6668 6.66658 14.6668H9.33325C11.8474 14.6668 13.1045 14.6668 13.8855 13.8589C14.6666 13.051 14.6666 11.7507 14.6666 9.15013V8.13611C14.6666 6.61049 14.6666 5.84768 14.3205 5.21532C13.9743 4.58297 13.342 4.1905 12.0772 3.40558L10.7439 2.57808C9.40699 1.74836 8.73854 1.3335 7.99992 1.3335C7.26129 1.3335 6.59284 1.74836 5.25594 2.57808L3.92261 3.40558C2.65788 4.19051 2.02552 4.58297 1.67939 5.21532ZM5.99992 11.5002C5.72378 11.5002 5.49992 11.724 5.49992 12.0002C5.49992 12.2763 5.72378 12.5002 5.99992 12.5002H9.99992C10.2761 12.5002 10.4999 12.2763 10.4999 12.0002C10.4999 11.724 10.2761 11.5002 9.99992 11.5002H5.99992Z" fill="#51525C" />
                    </svg>
                    {t('Home')}
                </Link>
                <span>/</span>
                <span>
                    {id?accounts?.find((val)=>val?.Id == id)?.AccountTitle:account?.AccountTitle}
                </span>
                <span>/</span>
                <span>{t('Deposit')}</span>
            </div>
            <div className={styles.withdrawal_body}>
                <div className={styles.withdrawal_form}>
                    <div className={styles.withdrawal_type}>
                        <div className={`${styles.type} ${depositType === 1 ? styles.active : ''}`} onClick={() => setDepositType(1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'2.4vh'} height={'2.4vh'} viewBox="0 0 24 24" fill="none">
                                <path d="M9 19C6.19108 19 4.78661 19 3.77772 18.3259C3.34096 18.034 2.96596 17.659 2.67412 17.2223C2 16.2134 2 14.8089 2 12C2 9.19108 2 7.78661 2.67412 6.77772C2.96596 6.34096 3.34096 5.96596 3.77772 5.67412C4.78661 5 6.19108 5 9 5L15 5C17.8089 5 19.2134 5 20.2223 5.67412C20.659 5.96596 21.034 6.34096 21.3259 6.77772C22 7.78661 22 9.19108 22 12C22 14.8089 22 16.2134 21.3259 17.2223C21.034 17.659 20.659 18.034 20.2223 18.3259C19.2134 19 17.8089 19 15 19H9Z" stroke="#FCFCFC" />
                                <path d="M9 9C7.34315 9 6 10.3431 6 12C6 13.6569 7.34315 15 9 15" stroke="#FCFCFC" />
                                <path d="M15 9C16.6569 9 18 10.3431 18 12C18 13.6569 16.6569 15 15 15" stroke="#FCFCFC" />
                                <path d="M9 5V18.5" stroke="#FCFCFC" strokeLinecap="round" />
                                <path d="M15 5V18.5" stroke="#FCFCFC" strokeLinecap="round" />
                            </svg>
                            {t('Bank Transfer')}
                        </div>
                        <div className={`${styles.type} ${depositType === 2 ? styles.active : ''}`} onClick={() => setDepositType(2)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'2.4vh'} height={'2.4vh'} viewBox="0 0 24 24" fill="none">
                                <path d="M12 8V6M12 18V16M9 12H13C14.1046 12 15 11.1046 15 10C15 8.89543 14.1046 8 13 8H9V12ZM9 12H14C15.1046 12 16 12.8954 16 14C16 15.1046 15.1046 16 14 16H9V12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#FCFCFC" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {t('Crypto currency')}
                        </div>
                        <div className={`${styles.type} ${depositType === 3 ? styles.active : ''}`} onClick={() => setDepositType(3)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'2.4vh'} height={'2.4vh'} viewBox="0 0 24 24" fill="none">
                                <path d="M19 14V20M19 20L21 18M19 20L17 18" stroke="#FCFCFC" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M22 12C22 8.22876 22 6.34315 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14" stroke="#FCFCFC" strokeLinecap="round" />
                                <path d="M10 16H6" stroke="#FCFCFC" strokeLinecap="round" />
                                <path d="M13 16H12.5" stroke="#FCFCFC" strokeLinecap="round" />
                                <path d="M2 10L22 10" stroke="#FCFCFC" strokeLinecap="round" />
                            </svg>
                            {t('Card')}
                        </div>
                    </div>
                    <div className={styles.amount}>
                        <label>{t('Deposit amount')}</label>
                        <input onChange={e => setAmount(e.target.value)} value={amount} placeholder={t('Deposit amount')} type='number' />
                    </div>
                    <div className={styles.amount}>
                        <label>{t('Full name')}</label>
                        <input onChange={e=>setFullName(e.target.value)} value={fullname} placeholder={t('Full name')} type='text' />
                    </div>
                    <div className={styles.buttons}>
                        <Link to='/cabinet'>{t('Cancel')}</Link>
                        <button onClick={()=>Replenish()}>{t('Continue')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
